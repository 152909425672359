import React from "react"
import { Link } from "gatsby"
import Layout from '../components/Layout'
import GradientContainer from '../components/GradientContainer'

// markup
export default function NotFoundPage() {
  return (
    <Layout>
      <GradientContainer>
        Seite nicht gefunden :-(
      </GradientContainer>
    </Layout>
  )
}
